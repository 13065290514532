import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  CreateDealer, CreateUpdateDealerRequestData, GetDealer, UpdateDealer,
} from '../../store/dealers/actions';
import { getLocationsRequest } from '../../store/settings/actions';
import { LocationsI } from '../../store/settings/requests';
import DealerCreationForm, { DealerFromData } from './DealerCreationForm';
import { CountriesOptions } from '../SettingsSections/data';
import { SelectOption } from '../../components/inputs/CustomSelect';
import { SchedulerRoles } from '../../store/constants';
import { formSelectOptions, roleHasAccess } from '../../helpers';

function DealerCreation() {
  const navigate = useNavigateHook();
  const params = useParams<{ id: string }>();
  const { showMessage } = useAppMessageContext();
  const { data: dealerData, isFetching: dealerLoading } = GetDealer(params.id || '');
  const locationsRequest = getLocationsRequest({ disableRequest: !params.id, dealer_id: params.id });
  const locationData: LocationsI[] = params.id ? locationsRequest?.data?.data || [] : [];
  const createDealerReq = CreateDealer();
  const updateDealerReq = UpdateDealer();

  const [racingJunkSlots] = useState<number>(dealerData?.rj_slots_used
    ? Number(dealerData?.rj_slots_used?.split('/')[0])
    : 0);

  const handleFormSubmit = (data: DealerFromData) => {
    const formData: CreateUpdateDealerRequestData = {
      ...data,
      dealer_data: {
        ...data.dealer_data,
        password: data.dealer_data.password || undefined,
        rj_dealer_id: data.dealer_data.rj_dealer_id || '',
        rj_slots: data.dealer_data.rj_slots?.value ? Number(data.dealer_data.rj_slots?.value) : undefined,
        rj_slots_used: data.dealer_data.rj_slots_used ? data.dealer_data.rj_slots_used : 0,
      },
      main_location: {
        ...data.main_location,
        country: data.main_location.country?.value ? String(data.main_location.country?.value) : '',
      },
      locations: data?.locations?.map((location) => ({
        ...location,
        country: location.country?.value ? String(location.country?.value) : '',
      })),
    };
    if (params.id) {
      updateDealerReq.mutate({ data: formData, id: params.id });
    } else {
      createDealerReq.mutate({ data: formData });
    }
  };

  useEffect(() => {
    if (createDealerReq.isSuccess) {
      showMessage({ type: 'success', message: 'Dealer Created successfully' });
      createDealerReq.reset();
      navigate(Pages.dealersPage);
    }
    if (updateDealerReq.isSuccess) {
      showMessage({ type: 'success', message: 'Dealer Updated successfully' });
      updateDealerReq.reset();
      navigate(Pages.dealersPage);
    }
  }, [createDealerReq.isSuccess, updateDealerReq.isSuccess]);

  const mainLocation = locationData.find((location) => location.is_main);
  const otherLocationsList = locationData.filter((location) => !location.is_main);
  const fromLocationData = (location: LocationsI | undefined) => ({
    id: location?.id,
    name: location?.name || '',
    contact_first_name: location?.contact_first_name || '',
    contact_last_name: location?.contact_last_name || '',
    website: location?.website || '',
    phone_number: location?.phone_number || '',
    email: location?.email || [''],
    street_address: location?.street_address || '',
    city: location?.city || '',
    state: location?.state || '',
    country: CountriesOptions.find((opt) => opt.value === location?.country) as SelectOption || null,
    zip_code: location?.zip_code || '',
    county: location?.county || '',
  });
  const formInitial: DealerFromData = {
    dealer_data: {
      dealership_name: dealerData?.dealership_name || '',
      primary_email: dealerData?.primary_email || '',
      password: '',
      first_name: dealerData?.first_name || '',
      last_name: dealerData?.last_name || '',
      phone_number: dealerData?.phone_number || '',
      server_address: dealerData?.server_address || '',
      ftp_login: dealerData?.ftp_login || '',
      ftp_password: dealerData?.ftp_password || '',
      filename: dealerData?.filename || '',
      fb_token: dealerData?.fb_token || '',
      stripe_id: dealerData?.stripe_id || '',
      ksl_folder_name: dealerData?.ksl_folder_name || '',
      ksl_password: dealerData?.ksl_password || '',
      rj_dealer_id: dealerData?.rj_dealer_id || '',
      rj_integration: !!dealerData?.rj_integration,
      rj_slots: dealerData?.rj_slots ? formSelectOptions([dealerData.rj_slots])[0] : null,
      rj_slots_used: racingJunkSlots,
      factory_feed: !!dealerData?.factory_feed,
      factory_feeds: dealerData?.factory_feeds || [],
      factory_feed_all_inventories: !!dealerData?.factory_feed_all_inventories,
    },
    access_scheduler: roleHasAccess(dealerData?.role, SchedulerRoles),
    fb_profiles: dealerData?.fb_profiles || false,
    dl_customer_id: dealerData?.dl_customer_id ? String(dealerData?.dl_customer_id) : '' || '',
    main_location: fromLocationData(mainLocation),
    locations: otherLocationsList.map((location) => fromLocationData(location)),
  };

  if (dealerLoading || locationsRequest.isFetching) {
    return null;
  }

  return (
    <StyledContainer>
      <DealerCreationForm
        onSubmit={handleFormSubmit}
        errorsProps={createDealerReq?.error?.response?.data.errors || updateDealerReq?.error?.response?.data.errors}
        editDealerMode={!!params.id}
        editableDealerValues={formInitial}
      />
    </StyledContainer>
  );
}

export default DealerCreation;

const StyledContainer = styled.div`
    width: 100%;
`;
